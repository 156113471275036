<template>
  <div :class="[containerClasses, 'zform-checkbox', { 'zform-checkbox-inline': inline }]">
    <input
      v-bind="$attrs"
      :id="computedId"
      v-model="internalModel"
      :value="value"
      :class="inputClasses"
      type="checkbox"
      :name="name"
    >
    <label
      :class="['zform-checkbox-label']"
      :for="computedId"
    ><slot /></label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: false,
    },

    containerClasses: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: undefined,
    },

    value: {
      // used when checked is an array
      type: [String, Number, Boolean],
      default: true,
    },

    toggle: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: undefined,
    },

    inline: {
      type: Boolean,
      default: false,
    },

    large: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const uuid = useId().replace(':', '_') // Temporary fix for https://github.com/nuxt/nuxt/issues/25755

    return {
      uuid,
    }
  },

  computed: {
    internalModel: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    computedId() {
      return this.id || this.uuid
    },

    inputClasses() {
      const type = this.toggle ? 'toggle' : 'box'
      return [
        'zform-checkbox-input',
        `zform-checkbox-${type}`,
        {
          [`zform-checkbox-${type}-large`]: this.large,
        },
      ]
    },
  },
}
</script>
